#inputFieldContainer {
   width: 100%;
   background-color: #f6f6f8;

}

#inputFieldHeader {
   padding-top: 25px;
   font-size: 22px;
   margin-left: 9%;
}

#nqBalanceTable {
   margin-left: 9%;
}

.inputFieldText {
   width: 400px;
   padding-right: 20px;
   font-size: 17px;
   margin-right: 20px;
}

.inputFieldTextRequired:after {
   content: " *";
   color: red;
   width: 400px;
   padding-right: 20px;
   font-size: 17px;
   margin-right: 20px;
}

.inputField {
   width: 400px;
   padding-right: 20px;
   font-size: 17px;
   margin-bottom: 4em;
   margin-right: 20px;
}

.employeeInput {
   height: 65px !important;
}

.inputContract {
   height: 65px !important;

}

.eventDropDownSelect {
   z-index: 5 !important;
}

#filterHeader {
   font-size: 20px;
   font-weight: bold;
}

#datePicker {
   border-radius: 5px;
}

#resetSearch {
   color: #0061A0;
}

#resetSearch:hover {
   cursor: pointer;
}

#submitRow {
   margin-top: 1em;
   padding-bottom: 18px;
}

.container {
   max-width: '100%'
}

.reactMultiSelect {
   margin-bottom: 37px;
   height: 5px;
}
#tablePrimary {
    width: 83%;
    margin-left: 9%;
}

#filterContainer {
    margin-left: 9%;
    float: left;
}

#filtersHeader {
    margin-bottom: 5px;
    font-size: 20px;
}

.filter {
    margin-right: 10px;
}

#searchResults {
    margin-left: 9%;
}

#searchFont {
    margin-top: 3%;
    font-size:18px;
}

#paginationContainer {
    float: right;
   
}

#pageList {
    float:right;
    
}

#paginationMenu {
    margin-top: 15px;
    margin-right: 50px;
    width: 250px;
    float: right;
    display: flex;
    
}

#erroredSelectWithLabel {
    margin-top: 12px;
    height: 40px;
    width: 75px;
}

#eventColumn {
    width: 130px;
}

.hrStyle {
    
    background-color: #e8e8e8 !important;
    border-width: 0px; 
    height: 1px;
    width: 91%;
    margin-left: 0%;
  }

  .hrStyleNoTable {
    
    background-color: #e8e8e8 !important;
    border-width: 0px; 
    height: 1px;
    width: 82%;
    margin-left: 9%;
  }
  
  #tableContainer {
    margin-top: -35px;
    margin-bottom: auto;
  }

  #searchResultsEmployeeInfo {
    margin-bottom: -50px;
    margin-left: 9%;
  }

 .disabled {
    color: rgba(0, 0, 0, 0.3);
    opacity: 0.3;
    pointer-events: none;
}


#eventDetailBackButton {
    margin-top: 3em;
    margin-left: 9%;
}
.legend-text {
    font-size: small !important;
}

.effectiveDateColumn:hover {
    cursor: pointer

 }

    

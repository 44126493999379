.left-border-darker {
    border-left: 2px solid #E8E8E8;
    margin-left: 10px !important;
    padding-right: auto;
 }
 
 tr {
    height: min-content !important;
 }
 
 td {
    height: min-content !important;
 }
 
 th {
    height: auto !important;
 }
 
 #eventDetailContainer {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
 }
 
 .sub-header {
    margin-top: 0% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
    padding-left: 0% !important;
    padding-right: 0% !important;
    padding-top: 0% !important;
    padding-bottom: 0% !important;
    text-align: center !important;
    font-size: small !important;
 }
 
 
 .event-top {
    margin-top: 0% !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
    padding-left: 0% !important;
    padding-right: 0% !important;
    padding-top: 0% !important;
    padding-bottom: 0% !important;
 }
 
 .event-border {
    border: 0px solid #FFF !important;
    padding-right: 4em;
 }
 
 .divider-bottom {
    margin-top: 0%;
    margin-bottom: 10px;
    padding-top: 0%;
    padding-bottom: 0%;
    border-color: #FFF;
    border-bottom-width: 0cm;
 }
 
 ul.list-style {
    margin-top: 0%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 2%;
    padding-right: 0;
    padding-top: 0%;
    padding-bottom: 0%;
    list-style-type: none;
 }
 
 ul li.whole-row {
    list-style-type: none;
    width: 100%;
    float: left;
 }
 
 ul li.one-half {
    list-style-type: none;
    width: 50%;
    float: left;
 }
 
 ul li.two-third {
    list-style-type: none;
    width: 66.666666%;
    float: left;
 }
 
 ul li.one-quart {
    list-style-type: none;
    width: 25%;
    float: left;
 }
 
 ul li.one-third {
    list-style-type: none;
    width: 33.333333%;
    float: left;
 }

